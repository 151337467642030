import Offense from "../Models/Offense";

const offenses =
[
    new Offense({
        id: 1,
        caseId: 1,
        date: "20-11-2017",
        code: "HER-0074",
        institution: "Maputo Central Prison",
        charge: "Heroine Trafficking",
        term: "3y 2m 0d"
    }),
    new Offense({
        id: 2,
        caseId: 1,
        date: "14-06-2021",
        code: "ASS-0074",
        institution: "Maputo Central Prison",
        charge: "Assault",
        term: "0y 6m 0d"
    }),

    new Offense({
        id: 3,
        caseId: 2,
        date: "05-02-2000",
        code: "MAR-0032",
        institution: "Maputo Central Prison",
        charge: "Armed Robbery",
        term: "2y 3m 0d"
    }),
    new Offense({
        id: 4,
        caseId: 2,
        date: "12-13-2018",
        code: "MAR-0032",
        institution: "Maputo Central Prison",
        charge: "Marijuana Trafficking",
        term: "1y 0m 0d"
    }),

    new Offense({
        id: 5,
        caseId: 3,
        date: "14-02-2003",
        code: "LSD-0074",
        institution: "Pretoria Central Prison",
        charge: "LSD Trafficking",
        term: "5y 6m 0d"
    }),
]

export default offenses

export function getOffence(id)
{
    return offenses.find(c => c.id == id)
}

export function getOffencesForCase(caseId)
{
    return offenses.filter(c => c.caseId == caseId)
}
import React, { Component } from 'react';
import { Header } from './Components';
import { Snackbar, Button, Input } from './Controls';

class Login extends Component 
{
  state = 
  {
    errors: 
    {
      form: []
    }
  }

  onChange = (itemToUpdate) => 
  {
    let errors = {...this.state.errors}
    for (let prop of Object.keys(itemToUpdate))
      delete errors[prop]

    this.setState({...itemToUpdate, errors});
  }

  isValid = (usernameOnly = false) =>
  {
    let isValid = true
    let errors = 
    {
      form: []
    }

    if (!this.state.username?.length)
    {
      errors["username"] = "Username is required"
      isValid = false
    }

    if (!usernameOnly)
    {
      if (!this.state.password?.length)
      {
        errors["password"] = "Password is required"
        isValid = false
      }
  
      if (isValid && (this.state.username !== 'TPC' || this.state.password !== '12345'))
      {
        // errors.form.push("Incorrect username or Password")
        errors.form.push("Incorrect Username")
        errors.form.push("Incorrect Password")
        isValid = false
      }
    }

    this.setState({errors})
    return isValid
  }

  onDismissFormError = (i) =>
  {
    let errors = this.state.errors
    errors.form.splice(i, 1)

    this.setState({ errors })
  }

  onLogin = () => 
  {
    if (!this.isValid()) return

    window.location.href = '/home';
  }

  onForgotPassword = () => 
  {
    if (!this.isValid(true)) return
    
    alert(`Recovery password has been sent to ${this.state.username}`)
  }

  render() {
    return (
      <div style={styles.page}>
        <Header/>
        <div style={styles.pageHeader}>Login</div>
        <div style={styles.pageBody}>
          <div style={styles.form}>
            <Input 
              label="Username" 
              value={this.state.username}
              error={this.state.errors.username}
              onChange={username => this.onChange({ username }) }
            />
            <Input 
              label="Password" 
              type="password"
              value={this.state.password}
              error={this.state.errors.password}
              onChange={password => this.onChange({ password }) }
            />

            <Button 
              appearance="simple"
              onClick={this.onForgotPassword}>
              Forgot Password
            </Button>
            <Button
              appearance="outline"
              onClick={this.onLogin}>
              Login
            </Button>
          </div>
        </div>
        <Snackbar
          messages={this.state.errors.form}
          // duration={10000}
          onDismiss={this.onDismissFormError}/>
      </div>
    );
  }
}

const styles = {
  page:
  {
    display: "flex",
    flexDirection: "column",
    height: "100vh"
  },
  pageHeader:
  {
    color: "white",
    background: "black",
    padding: 8,
    fontSize: "0.8rem"
  },
  pageBody:
  {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "#808080",
    color: "white",
    width: "100%",
    rowGap: 20
  },
  form:
  {
    display: "flex",
    background: "#030708",
    flexDirection: "column",
    borderRadius: 8,
    padding: 20,
    rowGap: 10
  }
};

export default Login;
// CriminalProfile.js

import React, { Component } from 'react';
import { getCase } from '../../Providers/cases';
import { getPerson } from '../../Providers/people';

class CriminalProfile extends Component 
{    
  state = {}

  componentDidMount = async () => {
    const queryParams = new URLSearchParams(window.location.search);

    const isViewOnly = queryParams.get('isViewOnly') === 'true';
    const id = queryParams.get('id');

    const person = getPerson(id)
    const caseData = getCase(id)

    const profile = `${process.env.PUBLIC_URL}/Assets/${id}/profile.jpg`
    

    this.setState({ person, case: caseData, profile })
  }

  render() {
    return (
        <img style={styles.profile} src={this.state.profile} />
    );
  }
}

const styles =
{
  profile:
  {
    // width: "50%",
    height: "80vh"
  }
}

export default CriminalProfile;
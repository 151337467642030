import Offense from "./Offense"

class Model
{
    id
    personId
    caseNumber
    modusOperandi

    /** @type {Offense[]} */
    offenses = []

    constructor(source = {})
    {
        this.id = source.id
        this.personId = source.personId
        this.caseNumber = source.caseNumber
        this.modusOperandi = source.modusOperandi
        this.offenses = source.offenses?.map(offense => new Offense(offense))
    }
}

export default class Case extends Model
{
    /** @param {Model} source */
    constructor(source)
    {
        super(source)
    }

    toModel = () => new Model(this)
}
// Profile.js

import React, { Component } from 'react';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import CriminalProfile from "./CriminalProfile"
import IdPassport from "./IdPassport"

class Profile extends Component {

  state = {
  }

  render() 
  {
    return (

        <div>
            <div style={styles.headerFlex}>
            <div>
                <img style={styles.logo} src="/Logo.png" alt="haha" />
            </div>
            <div style={{ textAlign: 'right', flex:'1' }}>
            <img style={{ maxWidth: '150px',padding: '1rem' }} src="/Emblems.png" alt="haha" />
            </div>
            </div>
            <hr />

            <Tabs style={styles.tabs}>
                <TabList style={styles.tabList}>
                    <Tab>Criminal Profile</Tab>
                    <Tab>ID / Passport</Tab>
                    <Tab>Other Documents</Tab>
                    <Tab>Images</Tab>
                </TabList>

                <TabPanel style={styles.tabPanel}>
                    <CriminalProfile></CriminalProfile>
                </TabPanel>
                <TabPanel style={styles.tabPanel}>
                    <IdPassport></IdPassport>
                </TabPanel>
                <TabPanel style={styles.tabPanel}>
                    <div></div>
                </TabPanel>
                <TabPanel style={styles.tabPanel}>
                    <div></div>
                </TabPanel>
            </Tabs>
        </div>
    );
  }
}

const styles=
{
    logo: {
        width: '300px',
        padding: '1rem'
    },
    headerFlex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    tabList:
    {
        background: 'black',
        color: 'white',
    },
    tabPanel:
    {
        background: 'grey',
        padding: "10px",
        textAlign: "center"
    }
}

export default Profile;
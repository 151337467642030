import React, { Component } from "react";
import PropTypes from 'prop-types';
import "./button.css"
import classNames from "classnames";

export default class Button extends Component
{
    static propTypes = 
    {
        description: PropTypes.string,
        appearance: PropTypes.string,
        to: PropTypes.string,
        onClick: PropTypes.func
    };
    
    static defaultProps = 
    {
        description: null,
        appearance: null,
        to: null,
        onClick: () => {}
    }

    get isValid()
    {
        return !this.props.error?.length
    }

    onClick = (e)  =>
    {
        if (this.props.to?.length)
            window.location.href = this.props.to;
        else
            this.props.onClick(e)
    }

    render()
    {
        return (
            <button
                className={classNames("button", this.props.appearance)}
                onClick={this.onClick}>
                <div className="content">{this.props.children}</div>
                {
                    this.props.description?.length &&
                    <div className="description">{this.props.description}</div>
                }
            </button>
        );
    }
}
import Case from "../Models/Case";
import { getOffencesForCase } from "./offenses";

const cases =
[
    new Case({
        id: 1,
        personId: 1,
        caseNumber: "#68943-203",
        modusOperandi: "He did it",
        offenses: getOffencesForCase(1)
    }),

    new Case({
        id: 2,
        personId: 2,
        caseNumber: "#68946-405",
        modusOperandi: "He did it",
        offenses: getOffencesForCase(2)
    }),

    new Case({
        id: 3,
        personId: 3,
        caseNumber: "#68944-204",
        modusOperandi: "He did it",
        offenses: getOffencesForCase(2)
    })
]

export default cases

export function getCase(id)
{
    return cases.find(c => c.id == id)
}

export function getCasesForPerson(personId)
{
    return cases.filter(c => c.personId == personId)
}
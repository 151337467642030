import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';

import { getPerson } from "../../Providers/people"
import { getCase } from "../../Providers/cases"
import { Button } from '../Controls';

export class CaseItem extends Component 
{
    state = { }

    itemPath = `/individual/details?id=${this.props.caseId}`

    static propTypes = 
    {
        caseId: PropTypes.number
    };

    static defaultProps = 
    {
        caseId: 0
    }

    componentDidMount = async () => 
    {
        const caseData = getCase(this.props.caseId)
        const personData = getPerson(caseData.personId)

        this.setState({ 
            case: caseData,
            person: personData,
            offenceCodes: caseData.offenses.map(offense => offense.code).join(", "),
            
        })
    }

    render() 
    {
        return (
            <div style={styles.content} >
                <div style={styles.column}>{this.state.case?.caseNumber}</div>
                <div style={styles.column}>{this.state.offenceCodes}</div>
                <div style={styles.column}>{this.state.person?.fullName}</div>
                <div style={styles.column}>{this.state.person?.passport}</div>
                <div style={styles.column}>{this.state.person?.idNumber}</div>
                <div style={styles.column}>{this.state.person?.citizen}</div>
                <div style={{...styles.column, ...styles.actions}}>
                    <Button
                        description="Vau/View"
                        appearance="dense"
                        to={`${this.itemPath}&isViewOnly=true`}>
                        <span className="material-icons">visibility</span>
                    </Button>
                    <Button
                        description="Editar/Edit"
                        appearance="dense"
                        to={`${this.itemPath}&isViewOnly=false`}>
                        <span className="material-icons">edit</span>
                    </Button>
                </div>
            </div>
        );
    }
}

const styles = 
{
    content: 
    {
        display:'flex',
        flexDirection: 'row',
        marginBottom: 10,
        padding: 10,
        columnGap: 10,
        borderRadius: 8,
        backgroundColor: '#efefef'
    },
    column: 
    {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: "1 1 0",
        fontSize: 14,
    },
    actions:
    {
        flexWrap: "wrap",
        columnGap: 4,
        rowGap: 4
    },
    spacer:
    {
        padding: "0px 8px"
    }
};
import Case from "./Case"

class Model
{
    id
    name
    aliases
    idNumber
    passport
    natationality
    citizen
    province
    cityTown
    gender
    race
    height
    weight
    eyeColour
    hairColour
    dateOfBirth = new Date

    /** @type {Case[]} */
    cases = []

    constructor(source)
    {
        this.id = source.id
        this.name = source.name
        this.surname = source.surname
        this.aliases = source.aliases
        this.idNumber = source.idNumber
        this.passport = source.passport
        this.natationality = source.natationality
        this.citizen = source.citizen
        this.province = source.province
        this.cityTown = source.cityTown
        this.gender = source.gender
        this.race = source.race
        this.height = source.height
        this.weight = source.weight
        this.eyeColour = source.eyeColour
        this.hairColour = source.hairColour
        this.dateOfBirth = new Date(source.dateOfBirth)
        this.cases = source.cases?.map(a => new Case(a))
    }
}

export default class Person extends Model
{
    /** @param {Model} source */
    constructor(source)
    {
        super(source)
    }

    get fullName()
    {
        return `${this.surname}, ${this.name}`
    }

    get age()
    {
        return Math.abs(new Date(new Date - this.dateOfBirth).getFullYear() - 1970);
    }

    toModel = () => new Model(this)
}
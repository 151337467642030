import React, { Component } from 'react';

export class Header extends Component 
{
    render()
    {
        return (
            <div>
                <div style={styles.headerFlex}>
                    <div>
                        <img style={styles.logo} src="/Logo.png" alt="haha" />
                    </div>
                    <div style={{ textAlign: 'right', flex:'1' }}>
                        <img style={{ maxWidth: '150px',padding: '1rem' }} src="/Emblems.png" alt="haha" />
                    </div>
                </div>
                <hr />
            </div>
        );
    }
}

const styles = 
{
    logo: 
    {
        width: '300px',
        padding: '1rem'
    },
    headerFlex: 
    {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    }
}
import React, { Component } from 'react';
import { getCase } from '../../Providers/cases';
import { getPerson } from '../../Providers/people';

function importAll(r) 
{
	let images = {};
	r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
	return images;
}
  

class IDPassport extends Component
{    
    state = {}
  
    componentDidMount = async () => {
      const queryParams = new URLSearchParams(window.location.search);
  
      const isViewOnly = queryParams.get('isViewOnly') === 'true';
      const id = queryParams.get('id');
  
      const person = getPerson(id)
      const caseData = getCase(id)
  
	  const path = `${process.env.PUBLIC_URL}/Assets/${id}/IDs_Passports/`
      const identityDocument = `${path}/id.jpg`
      const passport = `${path}/passport.jpg`

      this.setState({ person, case: caseData, identityDocument, passport })
    }
  
    render() 
    {
		return (
			<div>
				{/* <img style={styles.document} src={this.state.identityDocument} alt="id" /> */}
				<img style={styles.document} src={this.state.passport} alt="passport" />
			</div>
		);
    }
}

const styles = 
{
	document:
	{
		height: "80vh",
	}
}
  
export default IDPassport;
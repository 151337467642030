import Person from "../Models/Person";
import { getCasesForPerson } from "./cases";

const people = 
[
    new Person({
        id: 1,
        name: "Nelson Augusto",
        surname: "Bata",
        aliases: "Morina, Augusto",
        idNumber: "110500162749M",
        passport: "AB0965825",
        natationality: "Mozambique",
        citizen: "Mozambique",
        province: "Inhambane",
        cityTown: "Morrumbene",
        gender: "Male",
        race: "Black",
        height: "1.78m",
        weight: "87kg",
        eyeColour: "Brown",
        hairColour: "Black",
        dateOfBirth: "17-02-1981",
        cases: getCasesForPerson(1)
    }),

    new Person({
        id: 2,
        name: "Ernesto",
        surname: "Bamo",
        aliases: "Bombena, Ernie",
        idNumber: "110505402682D",
        passport: "AB6733382",
        natationality: "Mozambique",
        citizen: "Mozambique",
        province: "Gaza",
        cityTown: "Manjacacaze",
        gender: "Male",
        race: "Black",
        height: "1.91m",
        weight: "92kg",
        eyeColour: "Brown",
        hairColour: "Black",
        dateOfBirth: "03-09-1994",
        cases: getCasesForPerson(2)
    }),

    new Person({
        id: 3,
        name: "Wynand Johannes",
        surname: "Harmse",
        aliases: "",
        idNumber: "5906272341879",
        passport: "A06887816",
        natationality: "South Africa",
        citizen: "South Africa",
        province: "Gauteng",
        cityTown: "Boksburg",
        gender: "Male",
        race: "Whiet",
        height: "1.65m",
        weight: "64kg",
        eyeColour: "Blue",
        hairColour: "Brown",
        dateOfBirth: "27-06-1959",
        cases: getCasesForPerson(3)
    })
]

export default people

export function getPerson(id)
{
    return people.find(person => person.id == id)
}
export function nextIndividuals(begin, end, filters = {}) {
  let filteredMockData = mockData;

  // const keys = Object.keys(filters);

  // keys.forEach((filterItem) => {
  //   if (filterItem) {
  //     filteredMockData = filteredMockData.filter((item) => {
  //       return item[filterItem]?.toLowerCase().includes(filters[filterItem]?.toLowerCase());
  //     });
  //   }
  // });

  console.log(filters);

  if (filters.country !== '' ) {
      filteredMockData = filteredMockData.filter((item) => {
        return item?.country?.toLowerCase().includes(filters.country?.toLowerCase());
      })
  }

  if (filters.province !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.province?.toLowerCase().includes(filters.province?.toLowerCase());
    })
  }

  if (filters.towncity !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.towncity?.toLowerCase().includes(filters.towncity?.toLowerCase());
    })
  }

  if (filters.timeperiod !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.timeperiod?.toLowerCase().includes(filters.timeperiod?.toLowerCase());
    })
  }

  if (filters.offensecode !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.offensecode?.toLowerCase().includes(filters.offensecode?.toLowerCase());
    })
  }

  if (filters.caseno !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.caseno?.toLowerCase().includes(filters.caseno?.toLowerCase());
    })
  }

  if (filters.surname !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.surname?.toLowerCase().includes(filters.surname?.toLowerCase());
    })
  }

  if (filters.name !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.name?.toLowerCase().includes(filters.name?.toLowerCase());
    })
  }

  if (filters.aliase !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.aliase?.toLowerCase().includes(filters.aliase?.toLowerCase());
    })
  }
  if (filters.nationality !== '' ) {
    filteredMockData = filteredMockData.filter((item) => {
      return item?.nationality?.toLowerCase().includes(filters.nationality?.toLowerCase());
    })
  }
  return filteredMockData.slice(begin, end) || null;
}

export function getIndividualDetails(id) {
  return mockData.find((item) => item?.id === id);
}

export let mockData = [
  {
    id: '1',
    name: 'Wynand Johannes',
    surname: 'Harmse',
    aliase: '6910205276089',
    nationality: 'A06887816',
    caseno: '1969/10/20',
    offensecode: '53',
    timeperiod: 'Male',
    country: 'White',
    province: '0987654321',
    towncity: 'Cocaine',
  },
  {
    id: '2',
    name: 'Nelson Augusto',
    surname: 'Bata',
    aliase: '110500162749M',
    nationality: 'AB0965825',
    caseno: '1981/02/17',
    offensecode: '41',
    timeperiod: 'Male',
    country: 'African',
    province: '0987654321',
    towncity: 'Heroine'
  },
  {
    id: '3',
    name: 'Juvencio Luis',
    surname: 'Augusto',
    aliase: '110506389181Q',
    nationality: 'AB844991',
    caseno: '1992/11/04',
    offensecode: '30',
    timeperiod: 'Male',
    country: 'African',
    province: '0987654321',
    towncity: 'LSD'
  },
  {
    id: '4',
    name: 'Bamo',
    surname: 'Mocambicana',
    aliase: '110505402682D',
    nationality: 'AB733382',
    caseno: '1994/09/03',
    offensecode: '28',
    timeperiod: 'Male',
    country: 'African',
    province: '0987654321',
    towncity: 'Heroine'
  },
];
class Model
{
    id
    caseId
    date = new Date
    code
    institution
    charge
    term

    constructor(source = {})
    {
        this.id = source.id
        this.caseId = source.caseId
        this.date = new Date(source.date)
        this.code = source.code
        this.institution = source.institution
        this.charge = source.charge
        this.term = source.term
    }
}

export default class Offense extends Model
{
    /** @param {Model} source */
    constructor(source)
    {
        super(source)
    }

    toModel = () => new Model(this)
}
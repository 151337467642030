// Home.js

import React, { Component } from 'react';

import {
  nextIndividuals
} from '../Providers/Individuals';

import cases from '../Providers/cases';

import {
  CaseItem,
  Header
} from '../UI/Components'
import { Button } from './Controls';

class Home extends Component {

  state = {
    individuals: [],
    pageSize: 10,
    isBusy: false,
    eof: false,
    filters: {
      country: '',
      province: '',
      towncity: '',
      timeperiod: '',
      offensecode: '',
      caseno: '',
      surname: '',
      name: '',
      aliase: '',
      nationality: ''
    }
  };

  componentDidMount = async () => {
    this.pushNextItems();
  };

  pushNextItems = (initial = false) => {
    this.setState({ isBusy: true });

    let currentLength = initial ? 0 : this.state.individuals.length;

    console.log(currentLength);

    const newIndividuals = nextIndividuals(currentLength, currentLength + this.state.pageSize, this.state.filters);

    if (newIndividuals.length === 0) {
      console.log('eof');
      this.setState({ eof: true });
      //return
    }

    this.setState((prevState) => {

      return {
        individuals: [...(initial ? [] : prevState.individuals), ...newIndividuals],
        isBusy: false
      };
    });
  };

  onScroll = (event, a, b, c) => {
    this.setState({ isScrollable: true });
    const pixelsFromBottom = event.target.scrollHeight - event.target.offsetHeight - event.target.scrollTop;
    if (pixelsFromBottom >= event.target.offsetHeight / 2 || this.state.isBusy) {
      return;
    }

    this.setState({ isBusy: true });

    this.pushNextItems();
  }

  handleChange = (objectToChange) => {

    this.setState((prevState) => {
      return { filters: { ...prevState.filters, ...objectToChange } }
    });
  };

  render() {
    return (
      <div>
        <Header/>
        <div style={styles.actionBar}>
          <Button
            to="https://trilateralplanningcell.com/">
            Logout
          </Button>
        </div>
        <div style={styles.filterContent}>
          <div style={styles.filterColumn}>
            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Country</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.country} onChange={(event) => { this.handleChange({ country: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Province</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.province} onChange={(event) => { this.handleChange({ province: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>City/Town</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.towncity} onChange={(event) => { this.handleChange({ towncity: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Time Period</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.timeperiod} onChange={(event) => { this.handleChange({ timeperiod: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Offense Code</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.offensecode} onChange={(event) => { this.handleChange({ offensecode: event.target.value }) }} />
              </div>
            </div>
          </div>
          <div style={styles.filterColumn}>
            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Case Number</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.caseno} onChange={(event) => { this.handleChange({ caseno: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Surname</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.surname} onChange={(event) => { this.handleChange({ surname: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Name(s)</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.name} onChange={(event) => { this.handleChange({ name: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Aliase(s)</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.aliase} onChange={(event) => { this.handleChange({ aliase: event.target.value }) }} />
              </div>
            </div>

            <div style={styles.filterRow}>
              <div style={styles.filterLableColumn}>Nationality</div>
              <div style={styles.filterTextboxColumn}>
                <input style={styles.filterTextBox} type="text" value={this.state.filters.nationality} onChange={(event) => { this.handleChange({ nationality: event.target.value }) }} />
              </div>
            </div>
          </div>
        </div>
        <div style={{...styles.actionBar, ...styles.actionBarTransparent}}>
          <Button
            to='/individual/details'>
            Adicionar/Add
          </Button>
          <Button
            onClick={() => this.pushNextItems(true)}>
            Procurar/Search
          </Button>
        </div>

        <div style={styles.tableContent} onScroll={this.onScroll}>
          <div style={styles.headerRow}>
            <div style={styles.headerItem}>Case Number</div>
            <div style={styles.headerItem}>Offense Codes</div>
            <div style={styles.headerItem}>Full Name</div>
            <div style={styles.headerItem}>Passport</div>
            <div style={styles.headerItem}>Id Number</div>
            <div style={styles.headerItem}>Citizenship</div>
            <div style={styles.headerItem}></div>
          </div>
          {cases.map(({ id }) => <CaseItem key={id} caseId={id}/>)}
          {this.state.eof && <div style={styles.eof} >No more data...</div>}
        </div>
      </div>
    );
  }
}

let styles = {
  tableContent: 
  {
    margin: '1%',
    padding: 0
  },
  actionBar:
  {
    background: "#333333",
    columnGap: 4,
    borderRadius: 8,
    display: "flex",
    justifyContent: "flex-end",
    padding: 4,
    margin: "0px 10px 10px"
  },
  actionBarTransparent:
  {
    background: "transparent"
  },
  headerRow: 
  {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#333',
    color: '#efefef',
    columnGap: 10,
    textAlign: "center"
  },
  headerItem: 
  {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flex: "1 1 0",
    fontSize: 16,
    fontWeight: 'bold'
  },
  filterContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    margin: '0px 10px 10px',
    padding: 10,
    borderRadius: 5,
    backgroundColor: '#666',
    color: '#efefef',
  },
  filterColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  filterRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    padding: 5
  },
  filterLableColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  filterTextboxColumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3
  },
  filterTextBox: {
    width: '90%'
  },
  button: {
    ButtonRadius: '5'
  },
  eof: {
    textAlign: 'center',
    fontWeight: 'bold'
  },
  filterButton: {
    margin: '1%',
    fontSize: 18
  },
  logo: {
    width: '300px',
    padding: '1rem'
  },
  headerFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
}

export default Home;
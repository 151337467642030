import React, { Component } from "react";
import PropTypes from 'prop-types';
import classNames from "classnames";
import "./input.css"

export default class Input extends Component
{
    static propTypes = 
    {
        label: PropTypes.string,
        value: PropTypes.string,
        error: PropTypes.string,
        type: PropTypes.string,
        onChange: PropTypes.func
    };

    static defaultProps = 
    {
        label: "",
        value: "",
        type: "text",
        error: undefined,
        onChange: () => {}
    }

    get isValid()
    {
        return !this.props.error?.length
    }

    render()
    {
        return (
            <div className={classNames(
            {
                'input': true,
                'has-value': this.props.value?.length   
            })}>
                <div className="input-inner-wrapper">
                    <div className="label">{this.props.label}</div>
                    <input type={this.props.type}
                        className={classNames(
                        {
                            invalid: !this.isValid
                        })}
                        value={this.props.value}
                        name={this.props.label}
                        onChange={event => this.props.onChange(event.target.value)}>
                    </input>
                </div>
                <div className="error">{this.props.error}</div>
            </div>
        );
    }
}
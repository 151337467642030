import React, { Component, useEffect } from "react";
import PropTypes from 'prop-types';
import Button from "../Button";
import "./snackbar.css"

export default class SnackbarMessage extends Component
{
    static propTypes = 
    {
        duration: PropTypes.number,
        message: PropTypes.any,
        onDismiss: PropTypes.func
    };

    static defaultProps = 
    {
        duration: 5000,
        message: false,
        onDismiss: () => {}
    }

    timer = setTimeout(this.props.onDismiss, this.props.duration)

    render()
    {
        return (
            <div className="snackbar-message">
                {this.props.message}
                <Button 
                    simple={true}
                    onClick={this.props.onDismiss}>
                    Dismiss
                </Button>
            </div>
        );
    }
}
// App.js

import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './UI/Home';
import Login from './UI/Login';
import Profile from './UI/Profile';
import styling from "./App.css"

class App extends Component {
  render() {
    return (
      <Router>
        <div>
          <Routes>
              <Route exact path='/' element={< Login/>} />
              <Route exact path='/home' element={< Home/>} />
              <Route path='/individual/details' element={< Profile/>} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
import React, { Component } from "react";
import PropTypes from 'prop-types';
import SnackbarMessage from "./snackbarMessage";
import "./snackbar.css"

export default class Snackbar extends Component
{
    static propTypes = 
    {
        duration: PropTypes.number,
        message: PropTypes.array,
        onDismiss: PropTypes.func
    };

    static defaultProps = 
    {
        duration: 5000,
        messages: [],
        onDismiss: () => {}
    }

    render()
    {
        return (
            <div className="snackbar">
                {this.props.messages.map((message, i) => (
                    <SnackbarMessage
                        key={i}
                        message={message}
                        duration={this.props.duration}
                        onDismiss={() => this.props.onDismiss(i)}/>
                ))}
            </div>
        );
    }
}